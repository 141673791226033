// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rollbar from 'rollbar'

export class App {
  constructor() {
    // console.log('> App.constructor')
    this.pendingModules = 0
    this.modulesToImport = []
    this.uiInitialized = false
    this.apps = {}
    this.modulePending()
  }

  start() {
    this.initRollbar()
      .then(() => this.initConfiguration())
      .then(() => this.importModules())
      .then(() => this.moduleReady())
      // .then(() => this.initUI())
      // .then(() => console.log('<< App.constructor'))
    // console.log('< App.constructor')
  }

  initRollbar() {
    return fetch('/api/errconfig.json').then(
      (r) => r.ok ? r.json() : { enabled: false, accessToken: "disabled" }
    ).then(
      (c) => {
        this.rollbarConfig = c
        this.rollbar = new Rollbar(this.rollbarConfig)
      }
    )
  }

  initConfiguration() {
    // console.log('> App.initConfiguration')
    return fetch('/api/configuration.json').then(
      (r) => r.json()
    ).then(
      (c) => {
        this.configuration = c
        // console.log(this)
        // console.log('<< App.initConfiguration')
      }
    )
  }

  modulePending() {
    this.pendingModules += 1
  }

  moduleReady() {
    this.pendingModules -= 1
    if (this.pendingModules <= 0)
      this.initUI()
  }

  ready() {
    return this.pendingModules == 0
  }

  addModule(app) {
    this.modulesToImport.push(app)
  }

  importModules() {
    // console.log('> App.importModules')
    this.modulesToImport.forEach((app) => {
      const appInstance = new app()
      // console.log(app, app.appName, appInstance)
      window[app.appName] = appInstance
      this.importUI(window[app.appName])
    })
    // console.log('< App.importModules')
  }

  importUI(app) {
    // console.log(app, app.UI)
    if (app.UI) {
      // console.log(app.UI, app.UI.appName)
      this.apps[app.UI.appName] = app
    }
  }

  initUI() {
    // console.log('> App.initUI', this.pendingModules, this.uiInitialized)
    if (this.pendingModules > 0 || this.uiInitialized) return
    // console.log('>> App.initUI', this.apps)

    this.uiInitialized = true
    const toMount = document.querySelectorAll('[data-react-class]')
    for (let i = 0; i < toMount.length; i += 1) {
      const node = toMount[i]
      // console.log("node", node)
      const appName = node.getAttribute('data-react-class')
      // console.log("appName", appName)
      const app = this.apps[appName]
      // console.log("app", app)
      const component = app?.UI
      // console.log("component", component)
      // console.log(node, appName, app, component)
      if (component) {
        if (node.innerHTML.length === 0) {
          app.mountComponent(node, component)
        }
      } else {
        console.error(`can't render a component that is not part of App: ${appName}`)
      }
    }
    // console.log('< App.initUI')
  }

}
